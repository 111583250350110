/* AboutUs.css */

.about-us-section {
    background-color: #f9f9f9;
    padding: 40px 0;
    text-align: center;
  }
  
  .about-us-card {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row-reverse; /* Display text before image */
  }
  
  .about-us-text {
    flex: 1;
    padding: 20px;
    text-align: left;
  }
  .initials {
    color: #0e8e37; /* Desired color for the initials */
    font-weight: bold;
  }
  
  .about-us-text h2 {
    font-size: 32px;
    color: #008d36;
    margin-bottom: 10px;
  }
  
  .about-us-text p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .read-more-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #008d36;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .read-more-button:hover {
    background-color: #006a2e;
  }
  
  .about-us-image {
    flex: 1;
    text-align: center;
  }
  
  .about-us-image img {
    max-width: 100%;
    height: auto;
  }
  
  /* Media Queries */
  @media (max-width: 767px) {
    .about-us-card {
      flex-direction: column;
    }
  
    .about-us-text {
      text-align: left;
      
    }
  
    .about-us-text h2 {
      font-size: 20px;
    }
  
    .about-us-text p {
      font-size: 16px;
    }
  
    .about-us-image {
      text-align: center;
      margin-top: 20px;
    }
  }
  