/* Container for the two-column layout */
.garissa-county-action-plan-container {
  display: flex;
  margin-top: 90px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Project Description Card */
.project-description-card {
  flex: 1;
  width: 40%;
  background-color: #ffffff;
  padding: 5%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /* border-radius: 10px; */
}

.project-description-card .post-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.project-description-card .post-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.project-description-card .objectives h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #444;
}

.project-description-card .objectives ul {
  list-style-type: disc;
  padding-left: 20px;
}

.project-description-card .objectives ul li {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

/* Sponsor Section */
.sponsor-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.sponsor-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sponsor-logo {
  width: 200px;
  height: 100px;
}

.sponsor-name {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

/* Photo Gallery Card */
.photo-gallery-card {
  flex: 1;
  width: 40%;
  background-color: #ffffff;
  padding: 5%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /* border-radius: 10px; */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .garissa-county-action-plan-container {
    flex-direction: column;
  }

  .project-description-card,
  .photo-gallery-card {
    width: 90%;
    margin-bottom: 10px;
  }
  .sponsor-logo {
    width: 200px;
    height: 70px;
  }
  
  .sponsor-name {
    font-size: 18px;
    color: #333;
    font-weight: bold;
  }
}
