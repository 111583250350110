/* Preloader.css */

.preloader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff;
  }
  
  .logo {
    align-items: center;
    /* Add styles for your company logo container */
  }
  
  .logo img {
    width: 150px; /* Adjust the max-width of the logo */
  }
  
  .loading-bar {
    width: 30%; /* Adjust the width of the loading bar */
    align-items: center;
    height: 10px; /* Adjust the height of the loading bar */
    margin-top: 70px; /* Add space between logo and loading bar */
    background-color: #ccc; /* Color of the loading bar container */
    border-radius: 10px; /* Adjust border radius for rounded corners */
    overflow: hidden; /* Hide overflowing progress */
  }
  
  .progress {
    height: 100%; /* Ensure progress bar fills the container vertically */
    background-color: #0e8e37; /* Color of the loading progress */
    transition: width 0.5s ease-in-out; /* Smooth transition for progress width change */
  }
  @media (max-width: 767px) {
    .loading-bar {
      width: 50%; /* Adjust the width of the loading bar */
    }

  }
  