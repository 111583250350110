/* Global Styles */
body {
    font-family: Arial, sans-serif;
    background-color: #f2f5fa;
    
  }

body, html, .container {
  margin: 0;
  padding: 0;
}

  
  /* Add more global styles as needed */
  