/* Projects.css */
.projects-section {
  background-color: #f9f9f9;
  padding: 40px 0;
  margin-top: 90px;
  text-align: center;
}

.animated-title {
  font-size: 32px;
  color: #008d36;
  animation: fadeIn 1.5s ease-in;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

h2 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 20px;
}

h3 {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-card {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  margin: 10px;
  max-width: 300px;
}

img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
  margin-bottom: 20px;
}

.view-details-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #008d36;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.view-details-button:hover {
  background-color: #006a2e;
}

/* Media Queries */
@media (max-width: 767px) {
  .project-card {
    width: 90%;
  }
}
