/* ContactUs.css */

.contact-us1 {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 30vh;
}

.contact-title1 {
  font-size: 32px;
  color: #000000;
  text-align: center;
  margin-bottom: 20px;
}

.contact-details1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-info1 {
  flex: 1;
  padding: 20px;
  background-color: #f2f5fa;
  border-radius: 10px;
  margin: 10px;
}

.contact-info1 h2 {
  font-size: 24px;
  color: #008d36;
  margin-bottom: 10px;
}

.contact-info1 p {
  font-size: 16px;
  color: #666;
  line-height: 2.5;
  justify-content: left;
}

.contact-form1 {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin: 10px;
}

.contact-form1 h2 {
  font-size: 18px;
  color: #333;
  text-align: center; /* Centered for small devices */
  margin-bottom: 20px;
}

.form-group1 {
  margin-bottom: 20px;
}

.form-group1 label {
  display: block;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.form-group1 input,
.form-group1 textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

.form-group1 textarea {
  height: 150px;
  resize: vertical;
}

.submit-button1 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #008d36;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button1:hover {
  background-color: #006a2e;
}

/* Media Queries */
@media (max-width: 767px) {
  .contact-us1{
    margin-top: 20vh;
  }
  .contact-details1 {
    flex-direction: column;
  }

  .contact-info1,
  .contact-form1 {
    margin: 10px 0;
    padding: 10px; /* Added some padding for spacing on small devices */
  }
}
