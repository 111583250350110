/* navbar.css */
.navbar1 {
    position: fixed; /* Stick to the top */
    top: 30px; /* Position at the top of the viewport */
    left: 0;
    width: 100%; /* Full width */
    z-index: 999;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: background-color 0.3s;
    height: 60px;
  }
  
  .menu1 {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu-item1 {
    margin-right: 10px;
    position: relative;
  }
  
  .menu-item1 {
    margin-right: 10px;
    position: relative;
    padding: 5px 10px;
  }
  .menu-item1 a {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
    text-decoration: none;
    padding: 5px 10px;
    transition: color 0.3s;
  }
  
  .menu-item1 a:hover {
    color: #0e8e37;
  }
  
  .submenu1 {
    list-style: none;
    position: absolute;
    background-color: #ffffff;
    padding: 0;
    z-index: 2;
    margin-top: 0;
    display: none;
    min-width: 160px;
  }
  
  .submenu-open1 {
    display: block;
  }
  
  .submenu-item1 {
    margin: 5px;
  }
  
  .submenu-item1 a {
    display: block;
    padding: 5px 10px;
    color: #000000;
    text-decoration: none;
  }
  
  .submenu-item1 a:hover {
    color: #0e8e37;
  }
  
  .company-name {
    font-weight: bold;
    font-size: 28px;
    color: #0e8e37;
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }
  
  .logo-container1 {
    display: flex;
    align-items: center;
  }
  
  .logo {
    max-width: 100px; 
    height: 60px; 
    margin-left: 10px;
    margin-bottom: 0;
  }

  .activeItem{
    font-weight: bold;
    color: #f3930b !important;
  }


  /* Media Queries */
  @media (max-width: 767px) {
    /* Small devices */
    .menu1 {
      display: none;
    }
  
    .company-name {
      font-size: 32px;
      margin-left: 20px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    /* Medium devices */
    .menu1 {
      display: none;
    }
  
    .company-name {
      font-size: 32px;
      margin-left: 20px;
    }
  }
  
  /* Sidebar.css */
  .hamburger-menu {
    position: fixed;
    top: 60px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    cursor: pointer;
    z-index: 200;
  }
  
  .hamburger-menu .line {
    width: 100%;
    height: 2px;
    background-color: #0e8e37;
  }
  .sidebar.open {
    transform: translateX(0);
  }