.hero-section {
  position: relative;
  top: 60px;
  width: 100%;
  overflow: hidden;
  height: 100vh; /* Adjust as needed */
}

.slider-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.slider-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Increased opacity for better visibility */
  border-radius: 5px;
  opacity: 1; /* Set initial opacity to 1 */
  transition: opacity 0.7s ease, transform 0.7s ease; /* Add transition for smooth animation */
  width: auto; /* Adjust width to prevent overflow */
}

.slider-image:hover .hero-text {
  opacity: 1;
}

.hero-text h1 {
  color: #0e8e37;
  margin: 10px 0; /* Adjusted margin for better spacing */
  font-weight: bold;
  font-size: 2.0rem;
}

.heroDescription {
  color: #fff; /* Text color for descriptions */
  font-size: 1.2rem;
  font-weight: 100;
}

@keyframes slideAndFade {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.hero-text {
  animation: slideAndFade 0.7s ease;
}

.green-text {
  color: #f3930b;
  font-size: 1.4rem;
  font-weight: 600;
}

.orange-text {
  color: #ec9701;
}

.red-text {
  color: #eb1b2b;
}

.hero-button1 {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  margin-top: 20px;
  border: solid 2px #0e8e37;
  border-radius: 5px;
  z-index: 1;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for button */
}

.hero-button1:hover {
  background-color: #f3930b; /* Change the background color on hover */
  transform: scale(1.05); /* Add a slight scale effect on hover */
  cursor: pointer;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 10px;
  transition: background-color 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}


/* Adjust slider size for small and medium devices */
@media (max-width: 1024px) {
  .hero-text {
    font-size: 18px;
    max-width: 80%;
  }
  .hero-section{
    height: 90vh;
  }
}

/* Small devices (e.g., smartphones) */
@media (max-width: 768px) {
  .hero-section{
    height: 80vh;
  }
  .hero-text h1 {
    font-size: 1.8rem;
  }
  .slider-image{
    height: 80vh;
  }
  .hero-text {
    font-size: 1.4rem;
    padding: 10px; /* Reduce padding for smaller screens */
    width: 90%; /* Adjust width for smaller screens */
    top: 60%;
  }
  .heroDescription {
    font-size: 1rem;
  }
  .slick-prev {
    left: 2px;
  }
  
  .slick-next {
    right: 2px;
  }
}
