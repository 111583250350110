/* Vacancy.css */
.vacancy-page1 {
    margin: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    margin-top: 30vh;
  }
  
  .section1 {
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #f3930b;
    border-radius: 5px;
    padding: 10px;
  }
  
  .section-header1 {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    background-color: #f3930b;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
  }
  
  .section-content1 {
    padding: 10px;
  }
  
  .section-header1:hover {
    background-color: #0e8e37;
  }
  
  .vacancy-card1 {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .vacancy-card1 h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .vacancy-card1 p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .vacancy-card1 a {
    text-decoration: none;
    color: #f3930b;
    font-weight: bold;
    display: block;
  }

  /* Small screen size (e.g., mobile) */
@media (max-width: 768px) {
  .vacancy-page1 {
    margin-top: 20vh;
    padding: 5px;
  }
  
  .section1 {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .section-header1 {
    padding: 5px;
  }
  
  .vacancy-card1 h3 {
    font-size: 16px;
  }
  
  .vacancy-card1 p {
    font-size: 14px;
  }
}

/* Medium screen size (e.g., tablets) */
@media (max-width: 1024px) {
  .vacancy-page1 {
    margin: 15px;
    padding: 7px;
    margin-top: 20vh;
  }
  
  .section1 {
    margin-bottom: 15px;
    padding: 7px;
  }
  
  .section-header1 {
    padding: 7px;
  }
  
  .vacancy-card1 h3 {
    font-size: 17px;
  }
  
  .vacancy-card p {
    font-size: 15px;
  }
}
  