/* HelpUs.css */

.help-us-section {
    background-image: url('../../Assets//images//image9.jpg'); /* Add your background image URL */
    background-size: cover;
    background-position: center;
    color: #fff; /* Text color */
    padding: 40px 0;
    text-align: center;
  }
  
  .help-us-card {
    background-color: rgba(255, 255, 255, 0.7); /* Background color with opacity */
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .help-us-text h1 {
    font-size: 32px;
    color: #008d36;
    margin-bottom: 20px;
  }
  
  .help-us-text p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .money-transfer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .money-transfer-icons img {
    max-width: 100px;
    margin: 0 10px;
  }
  
  /* Media Queries */
  @media (max-width: 767px) {
    .help-us-card {
      padding: 20px;
    }
  
    .help-us-text h2 {
      font-size: 18px;
    }
  
    .help-us-text p {
      font-size: 16px;
    }
  
    .money-transfer-icons img {
      max-width: 60px;
    }
  }
  