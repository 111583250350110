/* ContactBar.css */

.contactBar {
  position: fixed; /* Stick to the top */
  top: 0; /* Position at the top of the viewport */
  left: 0;
  width: 100%; /* Full width */
  background-color: #0e8e37;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 12px;
  height: 15px;
  z-index: 999;
}

.contactInfo {
  display: flex;
  align-items: center;
}

.phone,
.email {
  margin-right: 20px;
  margin-left: 10px;
}

/* Style for social media icons */
.socialIcons2 a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #ffffff;
  border: 2px solid #ffffff; /* Border color */
  border-radius: 50%; /* Make it round */
  transition: background-color 0.3s, border-color 0.3s;
}
.socialIcons2 {
  display: flex;
  gap: 15px;
  margin-right: 50px;
  /* margin-top: 15px; */
}
.socialIcons2 a:hover {
  background-color: #0e8e37; /* Change background color on hover */
  border-color: #ff8c00; /* Change border color on hover */
}



/* Media Queries */
@media (max-width: 767px) {
  .contactInfo {
    margin-left: 15px;
    padding-left: 10px;
  }
  .socialIcons2 {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
 
  .contactInfo {
    margin-left: 15px;
    padding-left: 10px;
  }
  .socialIcons2 {
    margin-right: 10px;
  }
}
