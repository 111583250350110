/* About.css */
.about-container1 {
    display: grid;
    gap: 20px;
    padding: 20px;
    margin-top: 20vh;
  }
  
  .about-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .about h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  @media (min-width: 767px) {
    .about-container1 {
      grid-template-columns: repeat(2, 1fr); /* Two columns on medium and larger screens */
      margin-top: 20vh;
    }
  }
  
  @media (min-width: 1024px) {
    .about-container1 {
      grid-template-columns: repeat(2, 1fr); /* Maintain two columns on large screens */
    }
  }