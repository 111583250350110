/* Procurement page */
.procurement-page1 {
  margin: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  margin-top: 30vh; 
}

/* Section styles */
.section {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #0e8e37;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Added a subtle box-shadow for depth */
}

.section-header {
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  background-color: #0e8e37;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  transition: background-color 0.2s ease; /* Added a smooth transition on hover */
}

.section-header:hover {
  background-color: #f3930b;
}

/* Tender card styles */
.tender-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tender-card h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #0e8e37;
}

.tender-card p {
  font-size: 16px;
  margin-bottom: 10px;
}

.tender-card a {
  text-decoration: none;
  color: #0e8e37;
  font-weight: bold;
  display: block;
}

/* Small screen size (e.g., mobile) */
@media (max-width: 768px) {
  .procurement-page1 {
    margin-top: 20vh;
    padding: 5px;
  }
  
  .section {
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .section-header {
    padding: 5px;
  }
  
  .tender-card h3 {
    font-size: 16px;
  }
  
  .tender-card p {
    font-size: 14px;
  }
}

/* Medium screen size (e.g., tablets) */
@media (max-width: 1024px) {
  .procurement-page1 {
    margin: 15px;
    padding: 7px;
    margin-top: 20vh;
  }
  
  .section {
    margin-bottom: 15px;
    padding: 7px;
  }
  
  .section-header {
    padding: 7px;
  }
  
  .tender-card h3 {
    font-size: 17px;
  }
  
  .tender-card p {
    font-size: 15px;
  }
}
