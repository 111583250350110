/* ThematicAreas.css */

.thematic-areas-section {
  background-color: #f2f5fa;
  padding: 40px 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
}

.animated-title {
  font-size: 32px;
  color: #008d36;
  animation: fadeIn 1.5s ease-in;
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.thematic-card {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 20%;
  margin: 10px;
}

.thematic-card h2 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 10px;
  text-align: left;
}

.thematic-card p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  text-align: left;
}

/* Media Queries */
@media (max-width: 767px) {
  .thematic-card {
    width: 90%;
  }
}
