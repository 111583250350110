/* Container for the photo gallery */
.photo-gallery-container {
    padding: 20px;
    background-color: #f2f2f2;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 0px;
    width: 100%;
    margin: 0 auto;
  }
  
  /* Default styles for the gallery */
  .photo-gallery {
    width: 100%;
    height: 100%; /* Ensure the gallery covers the viewport height */
    background-color: #F2F2F2;
    /* border-radius: 15px; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the slide takes the full height */
  }
  
  .gallery-image {
    width: 100%;
    height: 70vh; /* Image covers the full container */
    object-fit: cover; /* Ensures the image covers the area without stretching */
    /* border-radius: 10px; */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Hide carousel dots */
  .carousel .control-dots {
    display: none;
  }
  
  /* Responsive styles */
  @media (max-width: 767px) {
    .photo-gallery-container {
      padding: 0px;
    }
  
    .gallery-image {
      max-height: 40vh; /* Adjust height for mobile devices */
      padding: 10px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .gallery-image {
      max-height: 60vh; /* Adjust height for tablets */
    }
  }
  