.project-summary-container {
    margin-top: 80px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
  }
  
  .table-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  .project-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  }
  
  .project-table th, .project-table td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .project-table th {
    background-color: #4CAF50;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 2px solid #388e3c;
  }
  
  .project-table tr {
    transition: background-color 0.3s ease;
  }
  
  .project-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .project-table tr:last-child td {
    border-bottom: none;
  }
  
  @media (max-width: 768px) {
    .project-summary-container {
        margin-top: 100px;
        padding: 0;
        border-radius: 0;
      }
      .table-title {
        font-size: 1.2rem;
        margin-bottom: 10px;

      }.project-table {
      width: 100%;
    }
  
    .project-table th {
      text-align: left;
      position: relative;
      top: 0;
      width: 100%;
      background-color: transparent;
      color: #333;
      font-size: 10px;
      background-color: #4CAF50;
      padding: 5px;
    }
    
    .project-table td {
      border: none;
      position: relative;
      padding: 2px;
      font-size: 9px;
      border-bottom: 1px solid #ddd;
    }
  
    .project-table tr {
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }
  