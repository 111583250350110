.core-values-section {
    text-align: center;
    padding: 50px 20px;
    background-color:#0e8e37;
  }
  
  .core-values-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .core-value {
    flex: 1 1 300px; /* Set a minimum width for the core value boxes */
    max-width: 100%;
    text-align: left;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .core-value h3 {
    color: #333;
  }
  
  .core-value p {
    color: #666;
    line-height: 1.6;
  }
  
  /* Adjust styles for smaller screens */
  @media (max-width: 768px) {
    .core-values-container {
      flex-direction: column; /* Stack core values vertically on smaller screens */
    }
    
    .core-value {
      flex: 1 1 auto; /* Allow core value boxes to expand to full width */
      margin-bottom: 20px; /* Add spacing between core values */
    }
  }
  