/* PartnersSection.css */

.partners-section {
    text-align: center;
    padding: 40px 0;
    background-color: #f2f5fa;
    width: 100%;
    max-width: 800px;
    margin: 10px auto;
  }
  
  .partners-section h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color:#008d36;;
  }
  
  .partners-section p {
    font-size: 18px;
    margin-bottom: 20px;
    color: #000000;
  }
  
  .partner-logos {
    margin-top: 20px;
  }
  
  .partner-logo img {
    max-width: 100px; 
    margin: 0 20px; 
  }
  
  /* Media Queries (for responsiveness) */
  @media (max-width: 767px) {
    .partners-section {
      max-width: 300px;
    }
    .partners-section h2 {
      font-size: 18px;
    }
  
    .partners-section p {
      font-size: 16px;
    }
  
    .partner-logo img {
      max-width: 60px; /* Adjust for smaller screens */
      margin: 0 10px;
    }

  }
  