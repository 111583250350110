/* ScrollToTopButton.css */
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: grey;
  color: #fff;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  opacity: 0;
}

.scroll-to-top-button.visible {
  opacity: 1;
}

.scroll-to-top-button:hover {
  background-color: #000;
}
