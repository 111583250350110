/* Footer.css */
.footer {
  background-color: #1a1a1a;
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
}

.footer h4 {
  margin-bottom: 10px;
  color: #fff;
  font-size: 24px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer li {
  margin-bottom: 5px;
}

.footer a {
  text-decoration: none;
  color: #ffffff; /* Set link color to white */
}

.footer a:hover {
  text-decoration: underline;
}

.footer-section li {
  color: #fff;
  text-decoration: none;
}

.submenu-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Style for submenu text and links */
.sub-menu {
  list-style: none;
  margin-top: 10px;
}

.sub-menu li {
  color: #eeeeee;
  display: flex;
  align-items: center;
}

.sub-menu a {
  text-decoration: none;
  color: red; /* Set link color to red */
  padding-left: 20px;
}

.sub-menu a:hover {
  text-decoration: underline;
  color: red; /* Set link hover color to red */
}

.rotate-icon {
  transform: rotate(45deg);
}

.footer-section {
  width: 30%;
}

/* Style for social media icons */
.socialIcons1 a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid #ffffff; /* Border color */
  border-radius: 50%; /* Make it round */
  transition: background-color 0.3s, border-color 0.3s;
}
.socialIcons1 {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}
.socialIcons1 a:hover {
  background-color: #0e8e37; /* Change background color on hover */
  border-color: #ff8c00; /* Change border color on hover */
}

.social-icons1 svg {
  width: 20px;
  height: 20px;
  color: #555; /* Adjust the color according to your design */
}

.plus-icon {
  color: #ffffff;
  font-size: 12px;
  padding-left: 5px;
}

.minus-icon {
  color: #ffffff;
  font-size: 12px;
  padding-left: 5px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 20px;
    padding-left: 50px;
  }

  .footer-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer ul {
    text-align: left;
  }
  .social-icons1 {
    padding: 10px;
  }
}
